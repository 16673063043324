
import DatasetsAPI from '@/services/api/Datasets.js';
import store from '@/store/store.js';

const state = {
	tree_data: [],
	customer_group: {},
	customer_group_permissions: {},
	logged_user_data_fields: {},
	parent_change_menu: false,
	pending_parent_change_cg: {},
	cg_fields: [],
};

const getters = {
	tree_data: (state) => state.tree_data,
	customer_group: (state) => state.customer_group,
	customer_group_permissions: (state) => state.customer_group_permissions,
	logged_user_data_fields: (state) => state.logged_user_data_fields,
	parent_change_menu: (state) => state.parent_change_menu,
	pending_parent_change_cg: (state) => state.pending_parent_change_cg,
	cg_fields: (state) => state.cg_fields,
};

const actions = {
	buildTree ({commit}) {
		var output = [];
		var children = [];

		for (var i = 0; i < store.getters.customer_groups.length; i++) {
			var current_item = store.getters.customer_groups[i];
			var label = current_item["name"];
			var parentid = current_item["parent"];

			var id = current_item["id"];
			if (children[parentid]) {
				var object = {
					id: id,
					parentid: parentid,
					label: label,
				};

				if (!children[parentid].children) {
					children[parentid].children = [];
				}

				children[parentid].children[children[parentid].children.length] = object;
				children[id] = object;

			} else {
				children[id] = {
					id: id,
					parentid: parentid,
					label: label,
					children: []
				};

				output[0] = children[id];
			}
		}
		commit('setTreeData', output);
	},
	toggleParentChangeMenu({commit}, cg = {}) {
		commit('setParentChangeMenu', cg);
	},
	changePendingParentChangeCgId({commit}, id) {
		commit('setPendingParentChangeCgId', id);
	},
	async handlePendingParentChange({commit}, parent_id) {
		store.dispatch('changePendingParentChangeCgId', parent_id);
		let cg = store.getters.pending_parent_change_cg;
		let response = await DatasetsAPI.updateCustomerGroup(cg);
		return response
	},
	async updateCustomerGroup({commit}, cg) {
		let response = await DatasetsAPI.updateCustomerGroup(cg);
		await store.dispatch('getCustomerGroups');
		return response
	},
	async deleteCustomerGroup({commit}, cg_id) {
		let response = await DatasetsAPI.deleteCustomerGroup(cg_id);
		await store.dispatch('getCustomerGroups');
		return response
	},
	async createCustomerGroup({commit}, data) {
		let response = await DatasetsAPI.createCustomerGroup(data);
		await store.dispatch('getCustomerGroups');
		return response
	},
	updateCustomerGroupPermissions({commit}, customer_group_permissions) {
		let permissions = {
			'media_library_feature': customer_group_permissions.includes("Media library"),
			'voice_channel': customer_group_permissions.includes("Voice"),
			'conference_bridge': customer_group_permissions.includes("Conference bridge"),
			'trigger_message_by_phone': customer_group_permissions.includes("Phone invoke"),
			'sms_channel': customer_group_permissions.includes("SMS"),
			'on_call_period': customer_group_permissions.includes("On Call core platform access"),
			'two_way_sms_link_permission': customer_group_permissions.includes("SMS links for 2 way messages"),
			'audio_noticeboard': customer_group_permissions.includes('Audio noticeboard'),
			'sms_quick_reports': customer_group_permissions.includes('SMS quick reports'),
			'minimum_staffing_requirements': customer_group_permissions.includes('Minimum staffing requirements'),
			'phone_invoke': customer_group_permissions.includes('Phone invoke'),
			'minimum_staffing_requirements': customer_group_permissions.includes('Minimum staffing requirements'),
			'merge_fields': customer_group_permissions.includes('Merge fields'),
			'sftp_imports': customer_group_permissions.includes('SFTP imports'),
		};

		commit('setCustomerGroupPermissions', permissions);
	},
	async getCustomerGroupSettings({commit}, cg_id) {
		let customer_group = await DatasetsAPI.getCustomerGroupSettings(cg_id);
		store.dispatch('updateNamingConventionOptions', customer_group);
		store.dispatch('updatePhoneNumbers', customer_group.root_group_phone_numbers);
		store.dispatch('updateCustomerGroupPermissions', customer_group.root_customer_group_permissions);
		store.dispatch('updateCustomerGroupNoticeboard', JSON.parse(customer_group.customer_group.settings));

		let is_cli_number = false;
		let customer_group_settings = JSON.parse(customer_group.customer_group.settings);
		if (customer_group_settings['customer_group_phone_number']) {
			is_cli_number = true;
			store.dispatch('updateCliNumber', {
				label: customer_group_settings['customer_group_phone_number'].number, value: customer_group_settings['customer_group_phone_number'].number
			})
		}
		if (customer_group_settings['noticeboard_numbers']) {
			let anb_numbers = customer_group_settings['noticeboard_numbers'].map((number) => {
				return {
					label: number.number,
					value: number.id
				}
			})

			store.dispatch('updateNoticeboardNumbers', anb_numbers);
		}
		store.dispatch('updateLoggedUserDataFields', customer_group);
		store.dispatch('updateIsCLINumber', is_cli_number);

		if (customer_group_settings['address_format']) {
			let naming_conventions = [];
			for (let item of customer_group_settings['address_format']) {
				for (let field of customer_group.customer_group_fields) {
					if(item === field['id']) {
						naming_conventions.push({label: field.label, value: field.id});
					}
				}
				if (item == ',') {
					naming_conventions.push({"label":", (comma)", "id": 123455, "sign":",", "value":123455})
				} else if (item == '.') {
					naming_conventions.push({"label":". (dot)", "id":123456, "sign":".", "value":123456})
				}
			}
			store.dispatch('updateNamingConventionList', naming_conventions);
		}

		store.dispatch('updateSMSSenderName', customer_group_settings.default_sms_sender_name);
		store.dispatch('updateEmailSenderName', customer_group_settings.default_email_sender_name);
		let default_tts_voice = {
			'value': '',
			'label': ''
		}
		if (customer_group_settings.default_tts_voice) {
			default_tts_voice = customer_group_settings.default_tts_voice
		}
		store.dispatch('updateDefaultTTSVoice', default_tts_voice);
		if(customer_group_settings.dial_back_message){
			store.dispatch('updateDialBackMessage', customer_group_settings.dial_back_message);
		}

		if (customer_group.customer_group.id != customer_group.customer_group.root_parent_id) {
			if (customer_group_settings['inherited_from'] != 0 || is_cli_number) {
				let edit_dial_back = false;
				store.dispatch('updateEditDialBack', edit_dial_back);
			}
		}
		let default_amd_message = {
			'message_type': 'tts_amd',
			'message': ''
		}

		if (customer_group_settings.default_amd_message != undefined && Object.keys(customer_group_settings.default_amd_message).length !== 0) {
			default_amd_message = customer_group_settings.default_amd_message
		}
		store.dispatch('updateDefaultAMDMessage', default_amd_message)

		if (customer_group_settings.send_verification_email) store.dispatch('updateVerificationEmail', customer_group_settings.send_verification_email)
		if (customer_group_settings.locked_users) {
			store.dispatch('updateLockedUsersAdmin', customer_group_settings.locked_users.admin)
			store.dispatch('updateLockedUsersOperator', customer_group_settings.locked_users.operator)
			store.dispatch('updateLockedUsersData', customer_group_settings.locked_users.data)
			store.dispatch('updateLockedUsersSender', customer_group_settings.locked_users.sender)
		}
		if (customer_group_settings.preferred_timezone) {
			store.dispatch('updateHasDefaultTimezone', true)
			store.dispatch('updateDefaultTimezone', customer_group_settings.preferred_timezone)
		}
		if (customer_group_settings.sms_quick_reports) store.dispatch('updateSMSQuickReports', customer_group_settings.sms_quick_reports)
		if (customer_group_settings.test_message_header) store.dispatch('updateTestMessageHeader', customer_group_settings.test_message_header)
		store.dispatch('updateOpeningText', customer_group_settings.opening_text)
		store.dispatch('updateClosingText', customer_group_settings.closing_text)
		let default_message_timeout = {
			"value": "1800",
			"label": "30 minutes"
		}
		if (customer_group_settings.default_message_timeout) {
			default_message_timeout = {
				label: customer_group_settings.default_message_timeout.text,
				value: customer_group_settings.default_message_timeout.value
			}
		}
		store.dispatch('updateDefaultMessageTimeout', default_message_timeout)
		store.dispatch('updateSendNotificationsToAdminsAboutInvalidRecipients', customer_group_settings.send_notifications_to_admins_about_invalid_recipients)
		store.dispatch('updateConferenceSettings', customer_group_settings.conference)
		if (customer_group_settings.two_way_sms) store.dispatch('updateTwoWaySMS', customer_group_settings.two_way_sms)
		if (customer_group_settings.force_two_way_sms) store.dispatch('updateForceTwoWaySMS', customer_group_settings.force_two_way_sms)

		commit('setCustomerGroupSettings', customer_group);
	},
	updateLoggedUserDataFields({commit}, customer_group) {
		let logged_user_data_fields = customer_group.logged_user;
		commit('setLoggedUserDataFields', logged_user_data_fields);
	},
	async getCgFields({ commit }, id) {
		let response = await DatasetsAPI.getCgFields(id)
		commit('updateCgFields', response)
	},
};

const mutations = {
	setTreeData: (state, tree_data) => state.tree_data = tree_data,
	setCustomerGroupSettings: (state, customer_group) => state.customer_group = customer_group,
	setCustomerGroupPermissions: (state, permissions) => state.customer_group_permissions = permissions,
	setLoggedUserDataFields: (state, logged_user_data_fields) => state.logged_user_data_fields = logged_user_data_fields,
	setParentChangeMenu: (state, cg) => {
		state.parent_change_menu = !state.parent_change_menu;
		state.pending_parent_change_cg = cg;
	},
	setPendingParentChangeCgId: (state, id) => {
		state.pending_parent_change_cg.parent = id;
	},
	updateCgFields: (state, fields) => (state.cg_fields = fields),
};

export default {state, getters, actions, mutations}
