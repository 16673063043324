import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	naming_convention_options: [],
	noticeboard_number_options: [],
	voice_numbers: [],
	is_cli_number: false,
	show_caller_dial_back_custom_audio: true,
	customer_noticeboard_nums: {},
	default_noticeboards: {},
	customer_noticeboard_nums_copy: [],
	naming_convention: null,
	address_format_example: '',
	cli_number: null,
	noticeboard_numbers: null,
	naming_convention_list: [],
	sms_sender_name: null,
	email_sender_name: null,
	default_tts_voice: null,
	default_tts_voice_text_dictionary: {
		english:
			'"Hello. Welcome to the Alert Cascade text to speech voice demonstration."',
		us_english:
			'"Hello. Welcome to the Alert Cascade text to speech voice demonstration."',
		polish: '"Cześć. Witamy w Alert Cascade tekst na mowę demonstracji głosu."',
		french: 'Bonjour. Bienvenue dans la démonstration vocale "Alert Cascade".',
		italian:
			'"Ciao. Benvenuti nel testo "Alert Cascade" per la dimostrazione vocale."',
		german:
			'"Hallo. Willkommen zum "Alert Cascade" Text zur Rede Stimme Demonstration"',
		spanish:
			'"Hola. Bienvenido a la demostración de voz de texto a voz de Alert Cascade."',
		portugese:
			'"Olá. Bem-vindo ao texto "Alert Cascade" para demonstração de voz"',
		russian:
			'Здравствуйте. Добро пожаловать в демонстрацию текста на голосовую "Alert Cascade"',
		turkish:
			'"Merhaba. Konuşma sesli gösterisine Alert Cascade metnine hoş geldiniz."',
		scottish_gaelic:
			'"Halò. Fàilte gu "Alert Cascade" teacs gu cainnt, guth taisbeanadh"',
		welsh:
			'"Helo. Croeso i\'r testun "Alert Cascade" i leferydd, arddangosiad llais"',
		danish:
			'"Hej. Velkommen til Alert Cascade tekst til tale voice demonstration"',
		swedish:
			'"Hallå. Välkommen till Alert Cascade -texten till talröstdemonstration"',
		norwegian:
			'"Hallo. Velkommen til Alert Cascade -teksten til tale demonstrasjon"',
		dutch:
			'"Hallo. Welkom bij de Alert Cascade tekst naar speech voice demonstration"',
		icelandic: '"Halló. Velkomin á Alert Cascade texta til ræðu rödd kynningu"',
		japanese:
			'"こんにちは。「Alert Cascade」テキスト - 音声音声デモンストレーションへようこそ"',
		romanian: 'Buna. Bine ați venit la demonstrația de voce "Alert Cascade"',
		catalan:
			'"Hola. Benvingut al text Alert Cascade a la demostració de veu de la parla"',
		portuguese:
			"'Olá. Bem-vindo à demonstração em voz do texto em cascata do Alert Cascade.'",
		czech: 'Ahoj. Vítejte v hlasové demonstraci textu na řeč kaskády.',
		greek:
			'Γειά σου. Καλώς ήλθατε στην επίδειξη φωνής κειμένου φωνής Alert Cascade.',
	},
	dial_back_message: null,
	edit_dial_back: true,
	default_amd_message: null,
	mutually_exclusive_sliders_datasets: {
		upload_recording_amd: true,
	},
	primary_country_code: null,
	send_verification_email: false,
	locked_users_admin: true,
	locked_users_operator: true,
	locked_users_data: true,
	locked_users_sender: true,
	has_default_timezone: false,
	default_timezone: null,
	apply_preferred_timezone: false,
	timezone_error: null,
	sms_quick_reports: false,
	test_message_header: true,
	opening_text: null,
	closing_text: null,
	default_message_timeout: null,
	send_notifications_to_admins_about_invalid_recipients: true,
	two_way_sms: false,
	force_two_way_sms: false,
}

const getters = {
	naming_convention_options: (state) => state.naming_convention_options,
	noticeboard_number_options: (state) => state.noticeboard_number_options,
	voice_numbers: (state) => state.voice_numbers,
	is_cli_number: (state) => state.is_cli_number,
	show_caller_dial_back_custom_audio: (state) =>
		state.show_caller_dial_back_custom_audio,
	customer_noticeboard_nums: (state) => state.customer_noticeboard_nums,
	default_noticeboards: (state) => state.default_noticeboards,
	customer_noticeboard_nums_copy: (state) =>
		state.customer_noticeboard_nums_copy,
	naming_convention: (state) => state.naming_convention,
	address_format_example: (state) => state.address_format_example,
	cli_number: (state) => state.cli_number,
	noticeboard_numbers: (state) => state.noticeboard_numbers,
	naming_convention_list: (state) => state.naming_convention_list,
	sms_sender_name: (state) => state.sms_sender_name,
	email_sender_name: (state) => state.email_sender_name,
	default_tts_voice: (state) => state.default_tts_voice,
	default_tts_voice_text_dictionary: (state) =>
		state.default_tts_voice_text_dictionary,
	dial_back_message: (state) => state.dial_back_message,
	edit_dial_back: (state) => state.edit_dial_back,
	default_amd_message: (state) => state.default_amd_message,
	primary_country_code: (state) => state.primary_country_code,
	send_verification_email: (state) => state.send_verification_email,
	locked_users_admin: (state) => state.locked_users_admin,
	locked_users_operator: (state) => state.locked_users_operator,
	locked_users_data: (state) => state.locked_users_data,
	locked_users_sender: (state) => state.locked_users_sender,
	sms_quick_reports: (state) => state.sms_quick_reports,
	test_message_header: (state) => state.test_message_header,
	opening_text: (state) => state.opening_text,
	closing_text: (state) => state.closing_text,
	default_message_timeout: (state) => state.default_message_timeout,
	send_notifications_to_admins_about_invalid_recipients: (state) =>
		state.send_notifications_to_admins_about_invalid_recipients,
	two_way_sms: (state) => state.two_way_sms,
	force_two_way_sms: (state) => state.force_two_way_sms,
	has_default_timezone: (state) => state.has_default_timezone,
	default_timezone: (state) => state.default_timezone,
	apply_preferred_timezone: (state) => state.apply_preferred_timezone,
	timezone_error: (state) => state.timezone_error,
}

const actions = {
	updateNamingConventionOptions({ commit }, customer_group_settings) {
		let options_list = customer_group_settings.customer_group_fields
		options_list = options_list.filter((c) => c.field_type !== 'MULTISELECT')

		options_list.push({
			label: ', (comma)',
			value: 123455,
			id: 123455,
			sign: ',',
		})
		options_list.push({
			label: '. (dot)',
			value: 123456,
			id: 123456,
			sign: '.',
		})
		commit('setNamingConventionOptions', options_list)
	},
	updatePhoneNumbers({ commit }, phone_numbers) {
		let noticeboard_number_options = []
		let voice_numbers = []
		for (let phone_number of phone_numbers) {
			let provider = phone_number['provider']
			if (provider === 'noticeboard' || provider === 'noticeboard-twillio') {
				noticeboard_number_options.push(phone_number)
			} else if (provider === 'twillio' && phone_number.is_voice) {
				voice_numbers.push(phone_number)
			}
		}

		commit('setNoticeboardNumberOptions', noticeboard_number_options)
		commit('setVoiceNumbers', voice_numbers)
	},
	async doesNoticeboardExist({ commit }) {
		let response = await DatasetsAPI.listAudioNoticeboards()
		let show_caller_dial_back_custom_audio = true
		let customer_noticeboard_nums_copy = []
		if (!response.length) {
			return
		}
		customer_noticeboard_nums_copy = JSON.parse(
			JSON.stringify(
				store.getters.customer_noticeboard_nums.noticeboard_numbers
			)
		)
		for (let number of response) {
			for (let noticeboard_number of customer_noticeboard_nums_copy) {
				if (number.phone_number.id === noticeboard_number.id) {
					noticeboard_number['noticeboard_created'] = true
					show_caller_dial_back_custom_audio = false
					break
				}
			}
		}

		commit('setCustomerNoticeboardNumsCopy', customer_noticeboard_nums_copy)
		commit(
			'setShowCallerDialBackCustomAudio',
			show_caller_dial_back_custom_audio
		)
	},
	updateCustomerGroupNoticeboard({ commit }, customer_group_settings) {
		let customer_noticeboard_nums = {}
		let default_noticeboards = {}
		if (
			customer_group_settings.noticeboard_numbers != undefined &&
			customer_group_settings.noticeboard_numbers.length
		) {
			customer_noticeboard_nums.noticeboard_numbers =
				customer_group_settings.noticeboard_numbers
			if (customer_noticeboard_nums.noticeboard_numbers) {
				store.dispatch('doesNoticeboardExist')
			}
			if (customer_group_settings.default_noticeboards != undefined) {
				default_noticeboards = customer_group_settings.default_noticeboards
			}
		}
		commit('setCustomerNoticeboardNums', customer_noticeboard_nums)
		commit('setCustomerDefaultNoticeboards', default_noticeboards)
	},
	updateIsCLINumber({ commit }, is_cli_number) {
		commit('setIsCLINumber', is_cli_number)
	},
	updateNamingConventionList({ commit }, naming_convention_list) {
		commit('setNamingConventionList', naming_convention_list)
	},
	updateCliNumber({ commit }, cli_number) {
		commit('setCliNumber', cli_number)
	},
	updateNoticeboardNumbers({ commit }, noticeboard_numbers) {
		commit('setNoticeboardNumbers', noticeboard_numbers)
	},
	updateSMSSenderName({ commit }, sms_sender_name) {
		commit('setSMSSenderName', sms_sender_name)
	},
	updateEmailSenderName({ commit }, email_sender_name) {
		commit('setEmailSenderName', email_sender_name)
	},
	updateDefaultTTSVoice({ commit }, default_tts_voice) {
		commit('setDefaultTTSVoice', default_tts_voice)
	},
	updateDialBackMessage({ commit }, dial_back_message) {
		commit('setDialBackMessage', dial_back_message)
	},
	updateEditDialBack({ commit }, edit_dial_back) {
		commit('setEditDialBack', edit_dial_back)
	},
	updateNoticeboardContent({ commit }, obj) {
		let default_noticeboards = JSON.parse(
			JSON.stringify(store.getters.default_noticeboards)
		)
		default_noticeboards[obj.number] = obj.val
		commit('setCustomerDefaultNoticeboards', default_noticeboards)
	},
	updateDefaultAMDMessage({ commit }, val) {
		commit('setDefaultAMDMessage', val)
	},
	updateDefaultAMDMessageContent({ commit }, val) {
		let default_amd_message = JSON.parse(
			JSON.stringify(store.getters.default_amd_message)
		)
		default_amd_message['message'] = val
		commit('setDefaultAMDMessage', default_amd_message)
	},
	updateAMDUploadType({ commit }, val) {
		commit('setAMDUpload', val)
	},
	toggleMutuallyExclusiveSlidersDatasets({ commit }, key) {
		commit('setMutuallyExclusiveSlidersDatasets', key)
	},
	updatePrimaryCountryCode({ commit }, primary_country_code) {
		commit('setPrimaryCountryCode', primary_country_code)
	},
	updateVerificationEmail({ commit }, send_verification_email) {
		commit('setVerificationEmail', send_verification_email)
	},
	updateLockedUsersAdmin({ commit }, locked_users_admin) {
		commit('setLockedUsersAdmin', locked_users_admin)
	},
	updateLockedUsersOperator({ commit }, locked_users_operator) {
		commit('setLockedUsersOperator', locked_users_operator)
	},
	updateLockedUsersData({ commit }, locked_users_data) {
		commit('setLockedUsersData', locked_users_data)
	},
	updateLockedUsersSender({ commit }, locked_users_sender) {
		commit('setLockedUsersSender', locked_users_sender)
	},
	updateHasDefaultTimezone({ commit }, has_default_timezone) {
		commit('setHasDefaultTimezone', has_default_timezone)
	},
	updateDefaultTimezone({ commit }, default_timezone) {
		commit('setDefaultTimezone', default_timezone)
	},
	updateApplyPreferredTimezone( {commit}, apply_preferred_timezone) {
		commit('setApplyPreferredTimezone', apply_preferred_timezone)
	},
	updateTimezoneErrorMessage( {commit}, error) {
		commit('setTimezoneErrorMessage', error)
	},
	updateSMSQuickReports({ commit }, sms_quick_reports) {
		commit('setSMSQuickReports', sms_quick_reports)
	},
	updateTestMessageHeader({ commit }, test_message_header) {
		commit('setTestMessageHeader', test_message_header)
	},
	updateOpeningText({ commit }, opening_text) {
		commit('setOpeningText', opening_text)
	},
	updateClosingText({ commit }, closing_text) {
		commit('setClosingText', closing_text)
	},
	updateDefaultMessageTimeout({ commit }, default_message_timeout) {
		commit('setDefaultMessageTimeout', default_message_timeout)
	},
	updateSendNotificationsToAdminsAboutInvalidRecipients(
		{ commit },
		send_notifications_to_admins_about_invalid_recipients
	) {
		commit(
			'setSendNotificationsToAdminsAboutInvalidRecipients',
			send_notifications_to_admins_about_invalid_recipients
		)
	},
	updateTwoWaySMS({ commit }, two_way_sms) {
		if (two_way_sms) {
			commit('setForceTwoWaySMS', false)
		}
		commit('setTwoWaySMS', two_way_sms)
	},
	updateForceTwoWaySMS({ commit }, force_two_way_sms) {
		if (force_two_way_sms) {
			commit('setTwoWaySMS', false)
		}
		commit('setForceTwoWaySMS', force_two_way_sms)
	},
	async updateCustomerGroupSettings({ commit }, customer_group_id) {
		let settings = {}

		let anb_numbers = []
		for (let number_option of store.getters.noticeboard_number_options) {
			for (let number of store.getters.noticeboard_numbers) {
				if (number_option.id === number.value) {
					anb_numbers.push(number_option)
				}
			}
		}
		let default_noticeboards = JSON.parse(
			JSON.stringify(store.getters.default_noticeboards)
		)
		if (anb_numbers != undefined) {
			for (let noticeboard_number of anb_numbers) {
				if (default_noticeboards[noticeboard_number.number] == undefined) {
					default_noticeboards[noticeboard_number.number] = ''
				}
			}
		}

		let cli_number = {}
		if (store.getters.cli_number) {
			cli_number = store.getters.voice_numbers.filter((number) => {
				return number.number === store.getters.cli_number.value
			})[0]
		}
		let default_tts_voice = JSON.parse(
			JSON.stringify(store.getters.default_tts_voice)
		)
		if (!default_tts_voice.value) {
			default_tts_voice = null
		}
		let country_code = undefined
		let customer_group_settings = JSON.parse(store.getters.customer_group.customer_group.settings);
		if (store.getters.primary_country_code && store.getters.primary_country_code.name) {
			country_code = JSON.parse(
				JSON.stringify(store.getters.primary_country_code)
			)
		} else {
			country_code = {
				name: customer_group_settings.country_code.country,
				dialCode: customer_group_settings.country_code.dial_code,
				iso2: customer_group_settings.country_code.country_code
			}
		}
		let address_format_list = []
		if (store.getters.naming_convention_list.length !== 0) {
			for (let i = 0; i < store.getters.naming_convention_list.length; i++) {
				if (
					store.getters.naming_convention_list[i].sign
				) {
					address_format_list.push(store.getters.naming_convention_list[i].sign)
				} else {
					address_format_list.push(
						store.getters.naming_convention_list[i].value
					)
				}
			}
		} else {
			address_format_list = ''
		}
		let default_message_timeout = {
			text: store.getters.default_message_timeout.label,
			value: store.getters.default_message_timeout.value,
		}

		settings = {
			address_format_list: address_format_list,
			default_amd_message: store.getters.default_amd_message,
			country_code: country_code,
			send_verification_email: store.getters.send_verification_email,
			customer_group_phone_number: cli_number,
			noticeboard_numbers: anb_numbers,
			default_noticeboards: default_noticeboards,
			default_tts_voice: default_tts_voice,
			default_sms_sender_name: store.getters.sms_sender_name,
			locked_users: {
				admin: store.getters.locked_users_admin,
				operator: store.getters.locked_users_operator,
				data: store.getters.locked_users_data,
				sender: store.getters.locked_users_sender
			},
			default_email_sender_name: store.getters.email_sender_name,
			sms_quick_reports: store.getters.sms_quick_reports,
			test_message_header: store.getters.test_message_header,
			default_message_timeout: default_message_timeout,
			two_way_sms: store.getters.two_way_sms,
			force_two_way_sms: store.getters.force_two_way_sms,
			send_notifications_to_admins_about_invalid_recipients:
				store.getters.send_notifications_to_admins_about_invalid_recipients,
		}
		if (store.getters.dial_back_message && store.getters.edit_dial_back) {
			settings['dial_back_message'] = store.getters.dial_back_message
		}

		if (store.getters.opening_text) {
			settings['opening_text'] = store.getters.opening_text
		}

		if (store.getters.closing_text) {
			settings['closing_text'] = store.getters.closing_text
		}

		if (store.getters.has_default_timezone && store.getters.default_timezone) {
			settings['preferred_timezone'] = store.getters.default_timezone
		}

		settings['apply_preferred_timezone'] = store.getters.apply_preferred_timezone

		const response = await DatasetsAPI.editCustomerGroupSettings(
			customer_group_id,
			settings
		)
		if(store.getters.customer_noticeboard_nums.noticeboard_numbers){
			for(let number of store.getters.customer_noticeboard_nums.noticeboard_numbers){
				if(number.noticeboard_created){
					var context = {
						"phone_number": number.number,
						"message": store.getters.default_noticeboards[number.number]
					}
					await DatasetsAPI.updateAudioNoticeboardByPhone(context)
					}
				}
			}
		return response
	},
}

const mutations = {
	setNamingConventionOptions: (state, naming_convention_options) =>
		(state.naming_convention_options = naming_convention_options),
	setNoticeboardNumberOptions: (state, noticeboard_number_options) =>
		(state.noticeboard_number_options = noticeboard_number_options),
	setVoiceNumbers: (state, voice_numbers) =>
		(state.voice_numbers = voice_numbers),
	setCustomerNoticeboardNums: (state, customer_noticeboard_nums) =>
		(state.customer_noticeboard_nums = customer_noticeboard_nums),
	setCustomerDefaultNoticeboards: (state, default_noticeboards) =>
		(state.default_noticeboards = default_noticeboards),
	setCustomerNoticeboardNumsCopy: (state, customer_noticeboard_nums_copy) =>
		(state.customer_noticeboard_nums_copy = customer_noticeboard_nums_copy),
	setIsCLINumber: (state, is_cli_number) =>
		(state.is_cli_number = is_cli_number),
	setShowCallerDialBackCustomAudio: (
		state,
		show_caller_dial_back_custom_audio
	) =>
		(state.show_caller_dial_back_custom_audio =
			show_caller_dial_back_custom_audio),
	setNamingConventionList: (state, naming_convention_list) =>
		(state.naming_convention_list = naming_convention_list),
	setCliNumber: (state, cli_number) => (state.cli_number = cli_number),
	setNoticeboardNumbers: (state, noticeboard_numbers) =>
		(state.noticeboard_numbers = noticeboard_numbers),
	setSMSSenderName: (state, sms_sender_name) =>
		(state.sms_sender_name = sms_sender_name),
	setEmailSenderName: (state, email_sender_name) =>
		(state.email_sender_name = email_sender_name),
	setDefaultTTSVoice: (state, default_tts_voice) =>
		(state.default_tts_voice = default_tts_voice),
	setDialBackMessage: (state, dial_back_message) =>
		(state.dial_back_message = dial_back_message),
	setEditDialBack: (state, edit_dial_back) =>
		(state.edit_dial_back = edit_dial_back),
	setDefaultAMDMessage: (state, default_amd_message) =>
		(state.default_amd_message = default_amd_message),
	setPrimaryCountryCode: (state, primary_country_code) =>
		(state.primary_country_code = primary_country_code),
	setVerificationEmail: (state, send_verification_email) =>
		(state.send_verification_email = send_verification_email),
	setLockedUsersAdmin: (state, locked_users_admin) =>
		(state.locked_users_admin = locked_users_admin),
	setLockedUsersOperator: (state, locked_users_operator) =>
		(state.locked_users_operator = locked_users_operator),
	setLockedUsersData: (state, locked_users_data) =>
		(state.locked_users_data = locked_users_data),
		setLockedUsersSender: (state, locked_users_sender) =>
		(state.locked_users_sender = locked_users_sender),
	setHasDefaultTimezone: (state, has_default_timezone) =>
		(state.has_default_timezone = has_default_timezone),
	setDefaultTimezone: (state, default_timezone) =>
		(state.default_timezone = default_timezone),
	setApplyPreferredTimezone: (state, apply_preferred_timezone) =>
		(state.apply_preferred_timezone = apply_preferred_timezone),
	setTimezoneErrorMessage: (state, error) =>
		(state.timezone_error = error),
	setSMSQuickReports: (state, sms_quick_reports) =>
		(state.sms_quick_reports = sms_quick_reports),
	setTestMessageHeader: (state, test_message_header) =>
		(state.test_message_header = test_message_header),
	setOpeningText: (state, opening_text) => (state.opening_text = opening_text),
	setClosingText: (state, closing_text) => (state.closing_text = closing_text),
	setDefaultMessageTimeout: (state, default_message_timeout) =>
		(state.default_message_timeout = default_message_timeout),
	setSendNotificationsToAdminsAboutInvalidRecipients: (
		state,
		send_notifications_to_admins_about_invalid_recipients
	) =>
		(state.send_notifications_to_admins_about_invalid_recipients =
			send_notifications_to_admins_about_invalid_recipients),
	setTwoWaySMS: (state, two_way_sms) => (state.two_way_sms = two_way_sms),
	setForceTwoWaySMS: (state, force_two_way_sms) =>
		(state.force_two_way_sms = force_two_way_sms),
}

export default { state, getters, actions, mutations }
