<template>
	<div class="header">
		<a id="hamburger-btn" @click="toggleMenu">
			<span id="start_menu" class="fa fa-bars fa-2x"></span>
		</a>
		<img :src="getLogoImage()" id="menu-logo" alt="Logo image"/>

		<ProfileSettings
			:customer_group_id="current_CG.customer_group.id"
			@logout="logout"
		/>
	</div>
</template>

<script>
import ProfileSettings from "@/components/utils/ProfileSettings.vue";
import { mapGetters, mapActions } from "vuex";

export default {
	name: "MainHeader",
	components: { ProfileSettings },
	methods: {
		...mapActions(["toggleMenu", "logout"]),
			getLogoImage() {
			if (this.current_CG.customer_group.brand_logo){
				const logo = this.current_CG.customer_group.brand_logo;
				const has_logo = logo.length > 61;
				if (has_logo) {
					return this.current_CG.customer_group.brand_logo;
					}
			}
			return require("../../assets/logo-text.svg");
		}
	},
	computed: mapGetters(["current_CG"])
};
</script>

<style scoped>
.header {
	align-items: center;
	z-index: 1001;
	position: sticky;
	top: 0;
	color: var(--ac-highlight-color);
	padding: 3px 14px;
	background-color: var(--ac-incident-header-color);
	border-bottom: 1px solid #000;
	display: flex;
	justify-content: space-between;
}

#hamburger-btn {
	padding: 10px;
	cursor: pointer;
	color: var(--ac-incident-hamburger-button-color);
}

#menu-logo {
	height: 72px;
	position: relative;
}

#account-btn-group {
	padding: 5px;
}

.user-icon-wrapper {
	width: 40px;
	height: 40px;
	background: #f0f0f2;
	border-radius: 45px;
	color: #192320;
	font-size: 14px;
	padding: 10px 1px;
	cursor: pointer;
}

@media (max-width: 768px) {
	#menu-logo {
		height: 42px;
		margin: 10px;
	}
}
</style>
